:root {
  --blue: hsl(190, 81%, 67%);
  --orange: hsl(32, 98%, 56%);
  --red: hsl(338, 95%, 56%);
  --green: hsl(80, 76%, 53%);
}

.red { color: var(--red); }

html {
    height: 100%;
}

body, .app {
  display: flex;
  color: #ddd;
  font-family: courier;
  background-color: #121212;
  font-size: 1em;
  line-height: 1.4;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  margin: 0;
  padding: 1em 2em;
  border-right: 1px dashed #ddd;
  float:left;
  height: 100%;
  color: var(--green);
}
nav p {
  margin: 0;
  padding: 0;
}
nav ul {
  margin: 0;
  padding: 0 1em;
  list-style: none;
}
nav ul li::before {
  content: "▸";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
nav ul li::after {
  content: "/";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #FD971F;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em;
}

nav ul a {
  color: #A6E22E;
}

.help {
  padding: 0;
  margin: 0 0 1em 0;
  color: #AEAF87;
  font-size: 0.8em;
}

section {
  float:left;
  width: 80%;
}

section p, section h2 {
  line-height: 1em;
  padding: 0 1em;
}

article h2 {
  font-size: 1em;
  color: var(--red);
}
